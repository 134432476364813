import "./src/styles/global.css";

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location;
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [`/`];

  // if the new route is part of the list above, do not scroll to top
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    return false;
  }

  window.scrollTo(0, 0);
};
